import styles from "./Divider.module.scss";

interface Props {
	orientation: "top" | "bottom";
}

export const Divider = ({ orientation }: Props) => {
	return (
		<div className={`${styles.divider} ${orientation == "top" ? styles.top : styles.bottom}`}>
			<svg xmlns="http://www.w3.org/2000/svg" id="Layer_2" viewBox="0 0 6760.71 184.07">
				<defs></defs>
				<g id="Layer_2-2">
					<g id="Laag_1">
						<path
							className="cls-1"
							d="M0,184.07H6760.71V49.08c-511.89,80.92-749.55,22.66-1118.11,.28-281.75-17.12-571.62-14.32-850.83,8.15-334.55,26.99-665.28,82.21-1004.65,66.9-232.25-10.53-446.99-53.49-676.2-71.43-576.19-45.01-1174.97,71.68-1751.92,28.06-228.45-17.28-363.84-39.63-586.7-65.88C549.18-11.01,332.67-5.51,0,49.08V184.07Z"
						/>
					</g>
				</g>
			</svg>
		</div>
	);
};
