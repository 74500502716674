import styles from "./CardLibrary.module.scss";
import { Card, TitleWrapper } from "../../components";

interface Props {
	setActiveModal: (modalName: string) => void;
}

export const CardLibrary = ({setActiveModal}: Props) => {
	const cards = [
		{
			image: "/img-sportmassage.png",
			title: "Sportmassage",
			modalName: "sportmassage",
		},
		{
			image: "/img-hoofdpijnmassage.png",
			title: "Hoofdpijnmassage",
			modalName: "hoofdpijnmassage",
		},
		{
			image: "img-ontspanningmassage.png",
			title: "Ontspanningsmassage",
			modalName: "ontspanningmassage",
		},
	];

	return (
		<>
			<TitleWrapper
				textAlign="center"
				title="Wat kan ik voor je doen?"
				subtitle="Behandelingen en Tarieven"
			/>
			<div className={styles.cardLibrary}>
				{cards.map((card) => (
					<Card title={card.title} image={card.image} key={card.image} onClick={() => setActiveModal(card.modalName)}/>
				))}
			</div>
		</>
	);
};
