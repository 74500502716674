import { Button } from '../Button/Button';
import { HoofdpijnModal } from './Modals/HoofdpijnModal';
import { OnstpanningModal } from './Modals/OnstpanningModal';
import { SportmassageModal } from './Modals/SportmassageModal';

interface Props {
    activeModal?: string;
    setActiveModal: (modalName: string | undefined) => void;
}

export const Modal = ({activeModal, setActiveModal}: Props) => {

    return (
        <div className={`modal ${!!activeModal && 'is-active'}`}>
            <div className="modal-bg" onClick={() => setActiveModal(undefined)}></div>
            <div className="modal-content-wrapper">
                <div className="modal-exit" onClick={() => setActiveModal(undefined)}><span>sluiten</span> X</div>
                <div className="modal-title-wrapper">
                    <div className="modal-icon">
                        <img src='https://img.icons8.com/wired/64/000000/euro-pound-exchange.png' alt='modal icon'/>
                    </div>
                    <h3 className="modal-title">{`Tarieven ${activeModal}`}</h3>
                </div>
                {activeModal === 'hoofdpijnmassage' ? <HoofdpijnModal/> : activeModal === 'ontspanningmassage' ? <OnstpanningModal/> : activeModal === 'sportmassage' ? <SportmassageModal/> : null }
                <Button buttonColor='primary' buttonText='Contact' buttonUrl='/#contact' onClick={() => setActiveModal(undefined)}/>
            </div>
        </div>
        )
}