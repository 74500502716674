export const Contact = () => {

	return (
        <section className="contact-section">
            <div className="contact-wrapper">
                <a className="contact-item" href="mailto:info@sportmassagepraktijkhetty.nl">
                    <div className="contact-icon"><img src="https://img.icons8.com/wired/128/000000/mailbox-closed-flag-down.png"/></div>
                    <h3 className="contact-title">Mail</h3>
                    <p className="contact-info">info@sportmassagepraktijkhetty.nl</p>
                </a>
                <a className="contact-item" href="https://goo.gl/maps/juspmEFhUXyx14UC7" target="_blank" rel="noreferrer">
                    <div className="contact-icon"><img src="https://img.icons8.com/wired/128/000000/home.png"/></div>
                    <h3 className="contact-title">Locatie</h3>
                    <p className="contact-info">Leeuwte 5 - Sint-Jansklooster</p>
                </a>
                <a className="contact-item" href="https://www.facebook.com/pages/category/Health---Wellness-Website/Sportmassagepraktijk-Hetty-103717721973483/" target="_blank" rel="noreferrer">
                    <div className="contact-icon"><img src="https://img.icons8.com/wired/128/000000/facebook--v1.png"/></div>
                    <h3 className="contact-title">Facebook</h3>
                    <p className="contact-info">Sportmassagepraktijk Hetty</p>
                </a>
                <a className="contact-item" href="tel:+31613758673">
                    <div className="contact-icon"><img src="https://img.icons8.com/wired/128/000000/apple-phone.png"/></div>
                    <h3 className="contact-title">Telefoon</h3>
                    <p className="contact-info">06 - 13758673</p>
                </a>
            </div>

        </section>
        
	);
};