import React, { useRef, useEffect } from "react";
import styles from "./InBetween.module.scss";
import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
	setInbetweenHeight: (arg0: string) => void;
	imageUrl: string;
	id?: string;
}

export const InBetween = ({ children, setInbetweenHeight, imageUrl, id }: Props) => {
	const element = useRef() as React.RefObject<HTMLDivElement>;
	let elementHeight = element.current?.offsetHeight ? element.current?.offsetHeight * 0.5 + "px" : "0px";

	const determineHeight = () => {
		setInbetweenHeight(elementHeight);
	};

	useEffect(() => {
		determineHeight();
	});

	if (typeof window !== "undefined") {
		window.addEventListener("resize", () => {
			determineHeight();
		});
	}

	return (
		<div className={styles.InBetweenWrapper} style={{ height: elementHeight }} id={id}>
			<div ref={element} className={`${styles.InBetween}`}>
				<div className={styles.textWrapper}>{children}</div>
				<img alt='inbetween' src={imageUrl} />
			</div>
		</div>
	);
};
