import { ModalBehandelingRow } from '../ModalBehandelingRow'

export const SportmassageModal = () => {
    return (
        <div className="behandelingen-wrapper">
            <ModalBehandelingRow title="1 uur:" price='€ 50,00'>
                <div className="behandeling-subtitle">Gehele benen, rug, nek en schouders</div>
            </ModalBehandelingRow>
            <ModalBehandelingRow title="3/4 uur" price='€ 35,00'>
                <div className="behandeling-subtitle">Gehele benen</div>
            </ModalBehandelingRow>
            <ModalBehandelingRow title="1/2 uur | keuze uit:" price='€ 25,00'>
                    <div className="behandeling-subtitle">• Rug, schouders en nek</div>
                    <div className="behandeling-subtitle">• Billen en benen achter</div>
                    <div className="behandeling-subtitle">• Benen achter en voeten</div>
            </ModalBehandelingRow>
        </div>
    )
}