import React from "react";
import styles from "./ContentBlock.module.scss";
import { ImageContainer } from "../ImageContainer/ImageContainer";
import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
	textContentAlign: "left" | "right" | "center";
	textContentWidth: number;
	imageUrl?: string;
}

export const ContentBlock = ({ textContentAlign, textContentWidth, imageUrl, children }: Props) => {
	return (
		<div className={`${styles.contentWrapper} ${styles[textContentAlign]}`}>
			{imageUrl && (
				<div
					className={styles.imageContent}
					style={{ "--element-width": textContentWidth + "%" } as React.CSSProperties}>
					<ImageContainer>
						<img src={imageUrl} />
					</ImageContainer>
				</div>
			)}
			<div className={styles.textContentWrapper}>
				<div
					className={styles.textContent}
					style={{ "--element-width": textContentWidth + "%" } as React.CSSProperties}>
					{children}
				</div>
			</div>
		</div>
	);
};
