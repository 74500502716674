import styles from "./Card.module.scss";
import { ImageContainer } from "../../components";

interface Props {
	image: string;
	title: string;
	onClick?: () => void;
}

export const Card = ({ image, title, onClick}: Props) => {
	return (
		<div className={styles.card}>
			<div onClick={onClick}>
				<ImageContainer className={styles.cardImage}>
					<img src={image} alt={`behandeling ${title}`}/>
				</ImageContainer>
				<h3>{title}</h3>
			</div>
		</div>
	);
};
