import { useState } from 'react';
import { Button } from '../Button/Button';

export const Nav = () => {

	const [hamburgerActive, setHamburgerActive] = useState(false)

	const toggleHamburgerMenu = () => {
		setHamburgerActive(!hamburgerActive)
	};

	return (
		<header>
        <div id="menu">
            <nav className="topnav">
                <a id="home"/>
                <ul className="navlinks">
                    <li><a href="#behandelingen">Behandelingen</a></li>
                    <li><a href="#massages">Massage technieken</a></li>
                    <li><a href="#overmij">Wie ben ik?</a></li>
                    <li><a href="#cadeaubon">Cadeau</a></li>
                    <li><a href="#contact">Contact</a></li>     
                </ul>
            </nav>
        </div>
        <div id="mobilemenu" className={hamburgerActive ? 'hamburger-menu is-active' : 'hamburger-menu'} role="navigation" >
            <div id="menuToggle">
          
              <div className='mobilemenu-icon'onClick={toggleHamburgerMenu} />
              <span></span>
              <span></span>
              <span></span>
              
              <ul className='mobilemenu-items'>
                <a onClick={toggleHamburgerMenu} href="#behandelingen"><li>Behandelingen</li></a>
                <a onClick={toggleHamburgerMenu} href="#massages"><li>Massage technieken</li></a>
                <a onClick={toggleHamburgerMenu} href="#overmij"><li>Wie ben ik?</li></a>
                <a onClick={toggleHamburgerMenu} href="#cadeaubon"><li>Cadeau</li></a>
				<Button buttonColor="primary" buttonText="Contact" buttonUrl="#contact" onClick={toggleHamburgerMenu}></Button>
              </ul>
            </div>
          </div>
    </header>
	);
};
