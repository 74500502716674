import { useState } from "react";
import {
	Header,
	CardLibrary,
	Section,
	ImageContainer,
	TitleWrapper,
	ContentBlock,
	InBetween,
	Button,
  Contact
} from "../src/components";
import "../src/styles/globals.scss"
import "../src/styles/variables.scss";
import { Modal } from './components/Modal/Modal';

function App() {
  const [inbetweenHeight, setInbetweenHeight] = useState<string>();
  const [activeModal, setActiveModal] = useState<string | undefined>(undefined);

  activeModal ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'unset';

  return (
    <>
    <Header />
    <Section id='behandelingen'>
      <CardLibrary setActiveModal={setActiveModal} />
      <Modal activeModal={activeModal} setActiveModal={setActiveModal}/>
    </Section>
    <Section  id='massages'>
      <ContentBlock imageUrl="/massagee.jpg" textContentAlign="left" textContentWidth={66}>
        <TitleWrapper textAlign="left" title="Sportmassage" subtitle="Massage technieken" />
        <p>
        Sportmassages bevorderen de bloedcirculatie, versnellen spierherstel en kunnen blessures voorkomen. Ze activeren ook spieren, wat ideaal is voor training. Deze massages verminderen spierpijn, ontspannen je hele lichaam en zijn geschikt voor klachten door overbelasting, stress of slechte houding. Ze bevorderen slaap, verbeteren je mentale welzijn en zorgen voor betere spierspanning. Sportmassages zijn zowel reactief als preventief effectief! 
        <br/><br/>
        Een sportmassage maakt gebruik van diepe, langzame massagetechnieken om bindweefsel los te maken en spierspanning te verminderen. Hoewel het soms wat ongemakkelijk kan aanvoelen, is dit een teken dat je lichaam herstelt. Als een sportmassage toch te intens voor je is, biedik ook ontspannende massages. We bespreken samen welke massage het beste bij je past.
        </p>
      </ContentBlock>
    </Section>
    <Section dividerBottom={true} dividerTop={true}>
      <ImageContainer fullWidth={true} flip>
        <img className='bottom-right' src="/running.png" alt="runner"/>
      </ImageContainer>
      <ContentBlock textContentAlign="right" textContentWidth={66}>
        <TitleWrapper textAlign="left" title="Bindweefselmassage" subtitle="Massage technieken" />
        <p>
        Bindweefselmassage wordt toegepast op de bindweefsellaag van het lichaam, dat zich tussen de spieren en de huid bevindt. Het is een intensieve massage, met als doel dat de spanning in de huid afneemt. Deze massage verbetert de doorbloeding van je lichaam en kan worden toegepast tijdens het masseren of als aparte techniek. 
        <br/><br/>
        Bindweefselmassage is gebaseerd op het idee van reflexzones op het lichaam die in verbinding staan met klieren, bloedvaten, zenuwen, spieren en organen. Dit betekent dat een prikkel in een bepaald gebied effect heeft op het corresponderende weefsel. Hierbij kan ook gebruik gemaakt worden van cups.
        </p>
      </ContentBlock>
    </Section>
    <Section >
      <ContentBlock imageUrl="/triggerpoint.jpg" textContentAlign="left" textContentWidth={66}>
        <TitleWrapper textAlign="left" title="Triggerpointmassage" subtitle="Massage technieken" />
        <p>
        Triggerpoints – oftewel spierknopen – zijn eigenlijk zeer lokale verkrampingen in spieren, waar als gevolg van gebrek aan doorbloeding en zuurstof een overgevoelige plek ontstaat. Dit veroorzaakt vaak pijn en soms uitstraling naar andere plekken dan waar de spierknoop zich bevindt. Met een triggerpointmassage wordt ‘de vinger op de juiste plek gelegd’, ontspant het spierweefsel, wordt de spier weer goed doorbloed en worden afvalstoffen afgevoerd.
        </p>
      </ContentBlock>
    </Section>
    <InBetween setInbetweenHeight={setInbetweenHeight} imageUrl="/over-mij.png" id="overmij">
      <TitleWrapper title="Wie ben ik?" textAlign="left" />
      <p>
        Mijn naam is Hetty Pot en in 2021 heb ik mijn eigen sportmassage praktijk geopend in Sint
        Jansklooster. Als fanatiek sportster ben ik veel met mijn lichaam bezig om uit te zoeken wat het
        beste werkt en weet ik wat voor effect blessures hebben op je lichaam. Door deze interesse in sport
        en het menselijk lichaam heb ik de opleiding Sportmassage/verzorging afgerond. 
        <br/><br/>Ondertussen blijf ik
        doorleren en heb ik onlangs de cursussen hoofdpijnmassage, kinesiotaping, triggerpointmassage en bindweefselmassage afgerond. Tijdens de sportmassage behandelingen pas ik zo nodig deze technieken toe, gericht op de betreffende blessure.
      </p>
    </InBetween>
    <Section extraPadding={inbetweenHeight} dividerTop={true} backgroundColor="#F6F4EF" id='cadeaubon'>
      <ContentBlock textContentAlign="center" textContentWidth={50}>
        <TitleWrapper textAlign="center" title="Cadeautje geven?" subtitle="Massage cadeau" />
        <p>
          Een massage is natuurlijk een heerlijk moment voor jezelf en dat maakt het een super leuk cadeau
          om te geven of te krijgen! Er zijn cadeaubonnen voor elke massagebehandeling en voor elke duur. Neem contact op voor meer informatie.
        </p>
        <Button buttonColor="primary" buttonText="Contact" buttonUrl="/#contact"></Button>
      </ContentBlock>
    </Section>
    <Section backgroundColor="var(--color-light-green)" id="contact">
      <Contact/>
    </Section>
    <footer>
        <div className="hannet"><p>Handmade by Hannet ©</p></div>
    </footer>
  </>
  );
}

export default App;
