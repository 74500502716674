import { ModalBehandelingRow } from '../ModalBehandelingRow'

export const OnstpanningModal = () => {
    return (
        <div className="behandelingen-wrapper">
            <ModalBehandelingRow title="1 uur" price='€ 45,00'>
                <div className="behandeling-subtitle">Gehele achterkant</div>
            </ModalBehandelingRow>
        </div>
    )
}