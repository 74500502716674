import { ModalBehandelingRow } from '../ModalBehandelingRow'

export const HoofdpijnModal = () => {
    return (
        <div className="behandelingen-wrapper">
            <ModalBehandelingRow title="1/2 uur" price='€ 25,00'>
                <div className="behandeling-subtitle">Hoofdpijnmassage</div>
            </ModalBehandelingRow>
        </div>
    )
}