import {  PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
	title: string;
    price?: string;
}

export const ModalBehandelingRow = ({title, price, children}: Props) => {
    return (
        <div className="behandeling-row">
            <div className="behandeling-info-wrapper">
                <h4 className="behandeling-title">{title} </h4>
                {children}
            </div>
            <h4 className="behandeling-price">{price}</h4>
        </div>
    )
}