import React from "react";
import styles from "./Button.module.scss";

interface Props {
	buttonColor: "primary" | "secondary";
	buttonText: string;
	buttonUrl: string;
	onClick?: () => void;
}

export const Button = ({ buttonColor, buttonText, buttonUrl, onClick }: Props) => {
	return (
		<a data-appearance={buttonColor} href={buttonUrl} className={styles.Button} onClick={onClick}>
			{buttonText}
		</a>
	);
};
